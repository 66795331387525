/* eslint-disable no-underscore-dangle */

// import { SheetsRegistry } from "jss";
// import {
//   createMuiTheme
// } from "@material-ui/core/styles";
// import {
//   createGenerateClassName
// } from "@material-ui/styles";

import { SheetsRegistry } from "jss";
import {
  createMuiTheme
  // createGenerateClassName
} from "@material-ui/core/styles";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({

  palette: {
    primary: {
      light: "#5462db",
      main: "#0038a8",
      dark: "#001478"
    },
    secondary: {
      light: "#ffff58",
      main: "#fcd116",
      dark: "#c4a000"
    },
    background: {
      default: "white"
    }
  },
  typography: {
    useNextVariants: true,
    fontColor: "#444444",
    fontFamily: ["Montserrat, Arial"].join(","),
    subtitle2: {
      fontSize: ".75rem"
    }
  },
  overrides: {
    body: {
      backgroundColor: "#FFFFFF"
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      }
    }
  }
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry()
    // The standard class name generator.
    // generateClassName: createGenerateClassName()
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
